












































































import {Vue, Component, Ref} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {TEvent} from '@/_types/event.type';
import {TEventSettings} from '@/_types/event-settings.type';
import QrcodeVue from 'qrcode.vue';
import TranslitHelper from '@/_helpers/translit.helper';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';
import DeviceHelper from '@/_helpers/device.helper';
import iconEwShare from '@/_modules/icons/components/icon-ew-share.vue';
import CookiesHelper from '@/_helpers/cookies-helper';
const translitHelper = new TranslitHelper();

type TGetTicketsButtonAttributes = {
  href: string;
  target: string;
  buttonText?: string;
}

@Component({
  components: {
    QrcodeVue,
    iconEwShare,
  }
})
export default class SectionHero extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/isLoading') isAuthLoading: boolean;

  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  public get heroSectionStyles(): {[key: string]: string} {
    const result: {[key: string]: string} = {};
    if (this.heroBackgroundImageUrl) {
      result.backgroundImage = 'url(' + this.heroBackgroundImageUrl + ')';
    }
    return result;
  }

  public get heroBackgroundImageUrl(): string {
    return (this.event && this.event.image) || '';
  }

  public get eventTypeName(): string {
    return (this.event && this.event.event_type && this.event.event_type.name) || '';
  }

  public get isShareEventHintVisible(): boolean {
    const hintViewsLimit = 3;
    const totalTimesShownCookieName = 'ewSEHC';
    const alreadyShownTodayCookieName = 'ewSEHT';

    const wasItShownToday = !!(CookiesHelper.readCookie(alreadyShownTodayCookieName));
    const totalTimesViewed = parseInt(CookiesHelper.readCookie(totalTimesShownCookieName) || '0', 10) || 0;
    const isViewsLimitReached = totalTimesViewed >= hintViewsLimit;

    if (!wasItShownToday && !isViewsLimitReached) {
      CookiesHelper.createCookie({
        name: alreadyShownTodayCookieName,
        value: 1,
        maxAge: 24 * 3600, // 24 hours
      });
      CookiesHelper.createCookie({
        name: totalTimesShownCookieName,
        value: totalTimesViewed + 1,
        maxAge: 365 * 24 * 3600, // 365 days
      });
      return true;
    }

    return false;
  }

  public get eventTypeSeoHref(): string {
    if (!this.event) {
      return null;
    }
    const ev = this.event;
    const typeName: string = (ev.event_type && ev.event_type.name) || '';
    const typeId: number = (ev.event_type && ev.event_type.id) || 0;
    const countryName: string = (ev.country && ev.country.name) || '';
    const countryId: number = (ev.country && ev.country.id) || 0;
    const eventsSlug = '/events-[typeSlug]---[countrySlug]---[typeId]---[countryId]/'
      .replace('[typeSlug]', translitHelper.getTransliterated(typeName))
      .replace('[typeId]', (typeId ? typeId.toFixed(0) : ''))
      .replace('[countrySlug]', translitHelper.getTransliterated(countryName))
      .replace('[countryId]', (countryId ? countryId.toFixed(0) : ''));

    return '/' + this.$i18n.locale + eventsSlug;
  }

  public get eventTitle(): string {
    return (this.event && this.event.title) || '';
  }

  public get eventTitleClasses(): {[key: string]: boolean} {
    const titleLength = this.eventTitle.length;
    return {
      'title-short': titleLength <= 30,
      'title-long': (titleLength > 30) && (titleLength <= 60),
      'title-very-long': titleLength > 60
    };
  }

  // TODO: does this make sense? dateStart milliseconds is always less than dateEnd milliseconds
  public isShowingEndDateNeeded(): boolean {
    return this.eventDateStart.getTime() < this.eventDateEnd.getTime();
  }

  public get eventDateStart(): Date {
    return (this.event && this.event.date_start) || new Date();
  }

  public get eventDateEnd(): Date {
    return (this.event && this.event.date_end) || new Date();
  }

  public get eventOrganizerLogoUrl(): string {
    return (this.event && this.event.org_logo) || '';
  }

  public get qrCodeUrl(): string {
    return window.location.href;
  }

  public get getTicketsButtonAttributes(): TGetTicketsButtonAttributes {
    if (!this.event) {
      return {
        href: null,
        target: null,
      };
    }

    const result: TGetTicketsButtonAttributes = {
      href: null,
      target: null,
      buttonText: '',
    };

    if (this.event.buy_ticket_url) {
      result.href = this.event.buy_ticket_url;
      result.target = '_blank';
      result.buttonText = this.$t('button.buyTickets') as string;
    }

    return result;
  }

  public get hasPersonalAccess(): boolean {
    return !!(this.event && this.event.personal && this.event.personal.has_access);
  }

  public get isQuickRegisterButtonVisible(): boolean {
    return !!this.getTicketsButtonAttributes.href;
  }

  public async showAuthPopup(): Promise<void> {
    await this.$store.dispatch('authStore/setAuthPopupTargetRoute', null);
    await this.$store.dispatch('authStore/setStandaloneAuthVisibility', true);
  }

  public mounted(): void {
    this.manageShareEventHintVisibility();
  }

  public shareEventHintClassName: string = 'share-event-hint-shown';

  public manageShareEventHintVisibility(): void {
    if (this.isShareEventHintVisible) {
      this.showShareEventHint();
      return;
    }
    this.hideShareEventHint();
  }

  public showShareEventHint(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add(this.shareEventHintClassName);
  }

  public hideShareEventHint(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove(this.shareEventHintClassName);
  }

  public onCloseShareEventClick(): void {
    this.hideShareEventHint();
  }

  @Ref('shareEventButton') shareEventButtonRef: Element;

  public getShareEventUrl(): string {
    try {
      const eventSlug: string = this.event.slug;
      return [
        window.location.origin.replace('app.', 'www.'),
        this.$i18n.locale,
        'events',
        eventSlug + '/',
      ].join('/');
    } catch {
      return window.location.href;
    }
  }

  public onShareClick(): void {
    this.closeSharer();

    if (!navigator || !navigator.share || (!DeviceHelper.isAndroid() && !DeviceHelper.isIOS())) {
      this.openSharer({
        eventTarget: this.shareEventButtonRef,
        url: this.getShareEventUrl(),
      });
      return;
    }

    navigator.share({
      url: this.getShareEventUrl(),
      text: this.$t('nativeShareText', { eventName: (this.event && this.event.title) || '' }) as string,
    });
  }

  public onShareHintWrapClick(): void {
    this.hideShareEventHint();
  }

}
